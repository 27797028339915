import React, { FC } from 'react'
import { LogoLink as LaunchpadLogoLink } from '../../shared/LogoLink'

export const LogoLink: FC<{ href: string; title: string }> = ({
  href,
  title,
}) => {
  return (
    <LaunchpadLogoLink href={href} title={title} showGreetzAnniversaryLogo />
  )
}
